import { action } from 'utils/mobx';
import axios from 'axios';
import { message, notification } from 'antd';
import PropertyStore  from 'stores/property';
import { CREATE_PROPERTY_URL, BASE_URL } from 'constants/api';
import { Property as IProperty } from "declarations/property";
import { saveAs } from 'file-saver';
import workspaceStore from "stores/workspace";
import {
  LOAD_PROPERTIES, LOAD_PROPERTY, EXPORT_PROPERTIES, UPDATE_PROPERTY, ZIP_GALLERIES
} from 'constants/action'
import { RENT_VALUE } from 'constants/mixed';

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
  createResourceRequest(params: IProperty): any
  createResource(params: IProperty, callback: Function): any
}

interface IResponseValues {
  properties: Array<IProperty>;
  count: number;
}

notification.config({
  placement: 'topRight',
  duration: 0
})

export class PropertyService implements Props {

  // LOAD resources
  async loadResourcesRequest (params: FilterType | {}): Promise<{ status: number, data: IResponseValues}> {
    return axios.get(CREATE_PROPERTY_URL, {
      params
    })
  }

  // LOAD resources
  async loadSelectResourcesRequest (params: FilterType | {}): Promise<{ status: number, data: IResponseValues}> {
    return axios.get(`${CREATE_PROPERTY_URL}/all`, {
      params
    })
  }

@action({ action: LOAD_PROPERTIES, minRequestTime: 500 })
async loadResources(params: FilterType = {}, pageNum: number | 'all' = 1) {
  // let { filters, page } = (ownerListStore || {});
  if(!workspaceStore.selectedId) {
    return null;
  }

  let page = pageNum;
  // PropertyStore.clearData();
  const queryParams = {
    // ...filters,
    page,
    ...params,
    workspace_id: workspaceStore.selectedId,
  };
  PropertyStore.setLoading(true);
  const response: { status: number, data: IResponseValues } = await this.loadResourcesRequest(queryParams);


  if (response.status == 200) {
    const values = response.data.properties || [];
    this.addValues(values, pageNum, response.data.count, queryParams);
  }
  PropertyStore.setLoading(false);
  return response;
}

@action({ action: LOAD_PROPERTIES, minRequestTime: 500 })
async loadSelectResources(params: FilterType = {}) {
  const queryParams = {
    // ...filters,
    ...params,
    workspace_id: workspaceStore.selectedId,
  };
  PropertyStore.setLoading(true);
  const response: { status: number, data: IResponseValues } = await this.loadSelectResourcesRequest(queryParams);

  if (response.status == 200) {
    const values = response.data.properties || [];
    this.addSelectValues(values, "all", response.data.count, queryParams);
  }
  PropertyStore.setLoading(false);
  return response;
}

  async resetFilters () {
    PropertyStore.resetFilter();
    this.loadResources({});
  }

  async changeFilters (newFilter: {}) {
    PropertyStore.updateFilter(newFilter);
    propertyService.loadResources({...PropertyStore.filterKeys, ...newFilter });
  }

  // LOAD resources
  async loadResourceRequest (property_id: number) {
    return axios.get(`${CREATE_PROPERTY_URL}/${property_id}`)
  }

  @action({ action: LOAD_PROPERTY, minRequestTime: 500 })
  async loadResource(property_id: number) {
    PropertyStore.updateHasAccess(true);
    try {
      const response: { status: number, data: Object} = await this.loadResourceRequest(property_id);

      if (response.status == 200) {
        let value: any = {};
        value = response.data;
        this.addSelectedValue(value);
      }
      return response;

    } catch (err: any) {
      if (err.response.status == 403) {
        PropertyStore.updateHasAccess(false);
      }
    }
  }

  async createResourceRequest(params: IProperty) {
    return axios.post(CREATE_PROPERTY_URL, { property:
      params
    })
  }


  async createResource(params: any, callback: Function) {

    const response: { data?: any, status: number } = await this.createResourceRequest({...params, workspace_id: workspaceStore.selectedId });

    if (response.status == 200) {
      callback();
      message.success('Property created!')
      if(response.data.errors.length > 0) {
        notification['error']({
          message: 'Error sent resources to portals',
          placement: 'topRight',
          duration: 0,
          description: response.data.errors
        });
      }
    }
  }

  async deleteResourceRequest(property_id: number) {
    return axios.delete(`${CREATE_PROPERTY_URL}/${property_id}`)
  }


  async deleteResource(property_id: number, callback: Function) {

    const response: { data?: any, status: number } = await this.deleteResourceRequest(property_id);

    if (response.status == 403) {
      notification['error']({
        message: "You don't have access for this operation",
        placement: 'topRight',
        duration: 0,
      });
      callback();
      return;
    }

    if (response.status == 200) {
      message.success('Property deleted!!')
      this.loadResources(PropertyStore.filterKeys, PropertyStore.page)
      callback()
    } else {
      if(response.data.errors.length > 0) {
        notification['error']({
          message: 'Error delete resources from portals',
          placement: 'topRight',
          duration: 0,
          description: response.data.errors
        });
      }
      callback()
    }

  }

  async updateResourceRequest(resource_name: string, resource: any) {
    return axios.put(`${BASE_URL}/${resource_name}/${resource.id}`, { ...resource })
  }

  @action({ action: UPDATE_PROPERTY, minRequestTime: 500 })
  async updateResource(resource_name: string, resource: Object, callback: Function) {

    const response: { data: any, status: number } = await this.updateResourceRequest(resource_name, resource);
    if (response.status == 200) {
      const textMessage: any = {
        'locations': 'Address',
        'details': 'Details',
        'properties': 'Property',
        'descriptions': 'Description',
        'prices': 'Price'
      }
      const data = response.data.property ? JSON.parse(response.data.property) : response.data
      this.updateValue(data)
      message.success(`${textMessage[resource_name]} updated!`)

      if(response.data.errors && response.data.errors.length > 0) {
        notification['error']({
          message: 'Error synchronisation for web resources',
          placement: 'topRight',
          duration: 0,
          description: response.data.errors
        });
      }
      callback()
    }

  }

  async downloadDocRequest(resource_id: number) {
    return axios.get(`${BASE_URL}/download_docs/${resource_id}`)
  }


  async downloadDocResource(property: IProperty) {

    const response: { data: any, status: number } = await this.downloadDocRequest(property.id);

    if (response.status == 200) {
      // const blob = new Blob([response.data])
      let serverUrl;
      if(window.location.origin.includes('localhost')){
        serverUrl = 'http://localhost:3000';
      } else {
        serverUrl = window.location.origin;
      }
      const prefix = property.operation.includes(RENT_VALUE) ? 'VR': 'VS';
      const fileName = `${prefix}${property.identifier} ${property.name['en']}`
      // const file = new File(`${serverUrl}/docx/${response.data.url}`, `${fileName}.docx`)
      const blob = await fetch(`${serverUrl}/docx/${response.data.url}`).then((resp: any) => resp.blob())
      saveAs(blob, `${fileName}.docx`)
      // saveAs(`${serverUrl}/docx/${response.data.url}`, `${fileName}.docx`)
    }

  }

  // LOAD resourceS
  async exportRequest (params: FilterType | {}){
    return axios.get(`${CREATE_PROPERTY_URL}/export`, {
      params
    })
  }

  @action({ action: EXPORT_PROPERTIES, minRequestTime: 500 })
  async exportResources(params: FilterType = {}, pageNum: number | 'all' = 1) {
    const filters = PropertyStore.filterKeys;
    let page = pageNum;
    const queryParams = {
      ...filters,
      workspace_id: workspaceStore.selectedId,
      // ...params,
      page
    };

    const response: any = await this.exportRequest(queryParams);

    if (response.status == 200) {
      const blob = new Blob([response.data])
      saveAs(blob, 'properties.csv')
    }

    return response;
  }

  // LOAD resourceS
  async getZipRequest (property_id: number){
    return axios({
      method: 'GET',
      responseType: 'blob',
      url: `${CREATE_PROPERTY_URL}/generate_zip?id=${property_id}`,
    });
  }

  @action({ action: ZIP_GALLERIES, minRequestTime: 500 })
  async getZipResources(property: IProperty) {

    const response: any = await this.getZipRequest(property.id);

    if (response.status == 200) {
      saveAs(response.data, 'gallery.zip')
    }

    return response;
  }

  str2bytes(str: any) {
    var bytes = new Uint8Array(str.length);
    for (var i=0; i<str.length; i++) {
        bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  }

  addSelectedValue(value: any) {
    PropertyStore.addSelectedValue(value);
  }

  addValues(values: Array<IProperty>, page: number | 'all', count: number, queryParams: {}) {
    PropertyStore.addValues(values, page, count, queryParams);
  }

  addSelectValues(values: Array<IProperty>, page: number | 'all', count: number, queryParams: {}) {
    PropertyStore.addSelectValues(values, page, count, queryParams);
  }

  updateValue(params: any) {
    PropertyStore.updateValue(params);
  }

}

export const propertyService = new PropertyService();

import { action } from 'utils/mobx';
import { request } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import noteStore  from 'stores/note';
import { NOTE_URL } from 'constants/api';
import { Filter } from "declarations/filters";
import { INote } from "declarations/note";
import contactStore  from 'stores/contact';
import clientStore  from 'stores/client';
import propertyStore  from 'stores/property';
import activityStore  from 'stores/activity';
import { LOAD_NOTES } from 'constants/action';


interface FilterType {
  notable_id: number;
  notable_type: string;
};

interface IResponse {
  status: number,
  data: any
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any;
  loadResourcesRequest(queryParams: FilterType): Promise<object>;
}

export class NoteService implements Props {

  async loadResourcesRequest (params?: FilterType): Promise<{ status: number, data: Array<INote>}> {
    return axios.get(NOTE_URL, {
      params
    })
  }

  @action({ action: LOAD_NOTES, minRequestTime: 500 })
  async loadResources(params?: FilterType) {

    const response: { status: number, data: Array<INote>} = await this.loadResourcesRequest(params);

    if (response.status) {
      let values = [];
      values = response.data;
      console.log("🚀 ~ file: noteService.ts:48 ~ NoteService ~ loadResources ~ values:", values)

      this.addValues(values);
    }

    return response;
  }


  @request()
  async createResourceRequest(params: INote) {
    return axios.post(NOTE_URL, { note:
      params
    })
  }


  async createResource(params: any, callback: Function) {

    const response: IResponse = await this.createResourceRequest(params);

    if (response.status == 201) {
      const ResourceStore = this.getStore(params);
      ResourceStore.addSelectedAttributes({notes_count: response.data.notes_count})
      callback();
      this.loadResources({notable_id: params['notable_id'], notable_type: params['notable_type']});
      message.success('Note created!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  @request()
  async updateResourceRequest(resource: any) {
    return axios.put(`${NOTE_URL}/${resource.id}`, { ...resource })
  }


  async updateResource(resource: Object, isActivity: boolean | undefined, callback: Function) {

    const response: { data: any, status: number } = await this.updateResourceRequest(resource);

    if (response.status == 200) {
      isActivity ? this.updateActivityValue(response.data) : this.updateValue(response.data);
      message.success('Note updated!')
      callback()
    }

  }

  @request()
  async deleteResourceRequest(resource_id: number) {
    return axios.delete(`${NOTE_URL}/${resource_id}`)
  }


  async deleteResource(resource: INote, callback: Function) {
    if(!resource.id) {
      return
    }
    const response: { status: number } = await this.deleteResourceRequest(resource.id);

    if (response.status) {
      message.success('Note deleted!!')
      this.loadResources({ notable_id: resource.notable_id, notable_type: resource.notable_type });
      callback()
    }

  }

  getStore(resource: any) {
    if(resource.notable_type == 'Property') {
      return propertyStore
    } else if (resource.notable_type == 'Client') {
      return clientStore
    } else {
      return contactStore
    }

  }

  addValues(values: Array<INote>) {
    noteStore.addValues(values);
  }
  updateValue(value: INote) {
    noteStore.updateValue(value);
  }
  updateActivityValue(value: INote) {
    activityStore.updateValue(value);
  }
}

export const noteService = new NoteService();

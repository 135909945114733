import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { includes } from 'lodash';
import PropertyStore from 'stores/property';
import LocaleStore from 'stores/locale';
import { Property as IProperty } from 'declarations/property'
import { propertyService } from '../../PropertyService';
import DateByCreator from 'components/DateByCreator';
import withoutImage from 'images/placeholder-image.png';
import { Table, Modal, Dropdown, Row, Col, Tag, Input, Radio, Button } from 'antd';
import { SALE_VALUE, RENT_VALUE, TRASPASO_VALUE, PRICE_STATUS_COLOR } from 'constants/mixed';
import { dealService } from 'services/DealService';
import loginStore from 'modules/login/loginStore';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { renderMenu } from "utils/menu";
import workspaceStore from "stores/workspace";


interface IData {
  filter?: any;
  hidePagination?: boolean;
  hideSearch?: boolean;
  actionComponent?(props: any): any;
}

const confirm = Modal.confirm;

const ListTable: FC<IData> = observer(({ filter, hidePagination, hideSearch, actionComponent }) => {

  const { t } = useTranslation();
  const lng = LocaleStore.locale;
  const workspace_id = workspaceStore.selectedId;

  useEffect(() => {
    if(workspace_id) {
      const page = hidePagination ? 'all' : PropertyStore.page;
      propertyService.loadResources(filter || {}, page);
      if (filter?.except_deal_id) {
        PropertyStore.updateFilter({except_deal_id: filter.except_deal_id})
      }
    }
    return () => PropertyStore.clearData();
  }, [workspace_id, filter])


  const columns: any = [
    {
      title: t('properties.table.property'),
      dataIndex: 'property',
      key: 'property',
      width: 520,
    }, {
      title: t('properties.table.sale'),
      dataIndex: 'sale',
      key: 'sale',
      width: 160
    }, {
      title: t('properties.table.rent'),
      dataIndex: 'rent',
      key: 'rent',
      width: 160
    }, {
      title: t('properties.table.area'),
      dataIndex: 'area',
      key: 'area',
      width: 98
    }, {
      title: t('properties.table.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 80
    }, {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 40,
    }
  ]


  const getData = (): any => {
    const dataSource = (PropertyStore.values || []).map((property) => {
      const details = property.detail_attributes;
      return {
        property: getPropertyContent(property),
        sale: getSaleContent(property),
        rent: getRentContent(property),
        area: <span className='ws-nw'>{details && details.total_area || 0} m<sup>2</sup></span>,
        created_at: <DateByCreator
        resource={property}
        showNew={filter && (property.label_deal_ids || []).includes(filter.except_deal_id)}
        />,
        actions: renderActions(property)
      }
    })

    return dataSource;
  }

  const renderActions = (property: IProperty) => {
    let actions;
    if (actionComponent) {
      actions = actionComponent(property.id)
    } else {
      actions = <Dropdown menu={{ items: renderMenu(getActionComponent(property)) }} trigger={['click']}>
        <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
      </Dropdown>
    }

    return actions;
  }

  const showConfirm = (property: IProperty) => {
    // if(property.website_ids.length > 0 || property.portal_ids.length > 0) {
    //   message.info(t('properties.confirmDelete'))
    // } else {
      confirm({
        title: t('properties.confirmDelete'),
        content: t("properties.confirmDelete"),
        onOk() {
          return new Promise((resolve, reject) => {

            // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            propertyService.deleteResource(property.id, resolve)
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() { },
      });
    // }
  }

  const getPropertyContent = (property: IProperty) => {
    const currentLng: any = lng == 'en-US' ? 'en': lng;
    const name: any = property.name;

    return (
      <Row align='middle' className='fx-nw'>
        <Col className="img-thumbnail">
          <img
            src={property.current_image_url || withoutImage}
            width='150'
            height='98'
            className='d-b'
          />
        </Col>
        <Col className='pl-10'>
          <div className='property-info'>
            <a className='link' target='_blank' href={`/properties/${property.id}/overview`}>{name[currentLng]}</a>
            <div className='property-location'>
              {property.location_attributes && property.location_attributes.address}
            </div>
            <div className='property-status'>
              <span className='mr-5'>{t('properties.table.status')}:</span>
              <span className={'circle mr-5 ' + (property.status || '')} />{property.status}
            </div>
            <div className='property-owner'>
              <span>{t('properties.new.owner')}: </span>
              { property.owner ?
                <a href={`/owners/${property.owner.id}/overview`} className='link' target='_blank'>{property.owner.name}</a> : <span> - </span>
              }
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  const getSaleContent = (property: IProperty) => {
    const price = property.price_attributes;

    if (!price || !(includes(property.operation, SALE_VALUE) || includes(property.operation, TRASPASO_VALUE))) {
      return null;
    }

    const convertStatus: any = {
      'is_available': 'available',
      'is_reserved': 'reserved',
      'sold': 'sold'
    }

    return (
      <div className='property-cell-price'>
        <div className='price-id'>
          {t('properties.new.id')}: <span className='property-identifier'>VS{property.identifier}</span>
        </div>
        <div className='status'>
          {t('properties.table.status')}: <Tag color={PRICE_STATUS_COLOR[property.sale_status]}>
            {convertStatus[property.sale_status]}
          </Tag>
        </div>
        <div className='price-value'>
          {t('properties.table.price')}: {`${price.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0} €
        </div>
      </div>
    )
  }

  const getRentContent = (property: any) => {
    const price = property.price_attributes;

    if (!price || !includes(property.operation, RENT_VALUE)) {
      return null;
    }

    return (
      <div className='property-cell-price'>
        <div className='price-id'>
          {t('properties.new.id')}: <span className='property-identifier'>VR{property.identifier}</span>
        </div>
        <div className='status'>
          {t('properties.table.status')}: <Tag color={PRICE_STATUS_COLOR[property.rent_status]}>
            {property.rent_status}
          </Tag>
        </div>
        <div className='price-value'>
          {t('properties.table.price')}: {`${price.monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0} € / {t('properties.month')}
        </div>
      </div>
    )
  }

  const getActionComponent = (property: IProperty) => {

    return [
      {
        key:`edit_property_${property.id}`,
        label: <Link className='edit-title' to={`/properties/${property.id}/edit`}>
          <EditOutlined />
          {t('properties.table.edit')}
        </Link>
      },
      {
        key:`delete_${property.id}`,
        label: <div onClick={() => showConfirm(property)}>
          <span className='delete-title'>
            <DeleteOutlined />
            {t('owners.table.delete')}
          </span>
        </div>,
        disabled: !loginStore.isAdmin
      }
    ]
  }

  const changePagination = (page: number) => {
    PropertyStore.setPage(page);
    propertyService.loadResources(filter || PropertyStore.filterKeys, page);
  }

  const selectButton = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    let param;
    if (value === 'all') {
      param = null;
      PropertyStore.resetFilter();
      if(filter.operations) {
        PropertyStore.updateFilter({
          operations: filter.operations,
          statuses: ['published'],
          sale_statuses: ['is_available'],
          rent_statuses: ['available']
        })
      }
    } else {
      param = true;
      PropertyStore.updateFilter({except_deal_id: filter.except_deal_id})
    }
    propertyService.changeFilters({is_filter_deal: param})
  }

  const pressMarkAllView = () => {
    dealService.updateLabels(filter.except_deal_id)
  }

  const setSearchText = (e: any) => {
    e.preventDefault();
    propertyService.changeFilters({search_properties: e.target.value})
  }

  const page = PropertyStore.page == 'all' ? 1 : PropertyStore.page;
  const pagination = hidePagination ? false : {
    pageSize: 10, onChange: changePagination, total: PropertyStore.count, defaultCurrent: page, showSizeChanger: false, current: page
  };

    return (
      <>
        {(!hideSearch && filter) && <Row justify='space-between' align='middle' className='mb-30'>
          <Col>
            <Radio.Group defaultValue="relevant" onChange={e => selectButton(e)} buttonStyle="solid">
              <Radio.Button value="relevant">{t('properties.onlyRelevant')}</Radio.Button>
              <Radio.Button value="all">{t('properties.all')}</Radio.Button>
            </Radio.Group>
          </Col>
          <Col>
            <Row justify='start'>
              <Col>
                <Button type='primary' onClick={pressMarkAllView} className='mr-20'>
                  { t('properties.markAllVieved') }
                </Button>
              </Col>
              <Col>
                <Input.Search
                  placeholder={t('properties.search')}
                  className='h-100p'
                  allowClear={true}
                  onChange={setSearchText}
                />
              </Col>
            </Row>
          </Col>
        </Row>}
        <div>
          <Table
            dataSource={getData()}
            columns={columns}
            bordered
            scroll={{ x: "100%" }}
            loading={PropertyStore.fetchLoading}
            pagination={pagination}
            className='general-table'
          />
        </div>
      </>
    );
})

export default ListTable;
